








































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import ReleaseSearch from '@/shared/components/onboarding/ReleaseSearch.vue';
import SiteLayout from '@/shared/components/site/SiteLayout.vue';
import ModalDspSearch from '@/shared/components/onboarding/ModalDspSearch.vue';

@Component({
  components: {
    ModalDspSearch,
    SiteLayout,
    ReleaseSearch,
  },
})
export default class HowItWorks extends Vue {
  $refs!: {
    searchContainer: HTMLElement,
    searchComponent: ReleaseSearch,
  };

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTouch') isTouch: boolean;

  searched: boolean = false;

  searchCriteria: string = '';

  progressLines: HTMLCollectionOf<HTMLElement>;

  progressPoints: HTMLCollectionOf<HTMLElement>;

  mounted() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll, { passive: true });

    this.$store.dispatch('onboarding/clear');
    window.addEventListener('click', this.clearSearch);

    this.$store.dispatch('mixpanel/resetTrackOnceEventList');
    this.$store.dispatch('mixpanel/track', {
      action: 'Zire.HIWPageViewed',
    });
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('click', this.clearSearch);
  }

  handleScroll() {
    if (!this.progressPoints || !this.progressLines) {
      const container = document.getElementById('how-it-works');
      if (container) {
        this.progressPoints = container.getElementsByClassName('point') as HTMLCollectionOf<HTMLElement>;
        this.progressLines = container.getElementsByClassName('line') as HTMLCollectionOf<HTMLElement>;
      }
    }

    const docCenter = document.documentElement.clientHeight / 2;

    if (this.progressPoints) {
      [...this.progressPoints].forEach((el: HTMLElement) => {
        if (el.getBoundingClientRect().top < docCenter) {
          el.classList.add('active');
          el.parentElement!.previousElementSibling!.classList.add('active');
          el.parentElement!.nextElementSibling!.classList.add('active');
        } else {
          el.classList.remove('active');
          el.parentElement!.previousElementSibling!.classList.remove('active');
          el.parentElement!.nextElementSibling!.classList.remove('active');
        }
      });
    }

    if (this.progressLines) {
      [...this.progressLines].forEach((el: HTMLElement) => {
        const scrollPercent = this.scrollPercent(el.getBoundingClientRect(), docCenter);
        el.style.setProperty('--scroll', `${scrollPercent}%`);
      });
    }
  }

  scrollPercent(rect: DOMRect | ClientRect, docCenter: number): number {
    if (rect.top > docCenter) {
      return 0;
    }
    if (rect.bottom < docCenter) {
      return 100;
    }
    return ((docCenter - rect.top) / (rect.bottom - rect.top)) * 100;
  }

  openModalForMobile(evt: any) {
    if (this.isMobile) {
      this.$root.$emit('bv::show::modal', 'modal-dsp-search', evt.target);
      window.scrollTo(0, 0);
    }
  }

  itemClicked(clickedItem?: string) {
    this.$store.dispatch('mixpanel/track', {
      properties: {
        clickedItem,
      },
      action: 'Zire.HIWPageItemClicked',
    });
  }

  clearSearch(evt: any) {
    if ((this.$refs.searchContainer && this.$refs.searchComponent.searched) && !this.$refs.searchContainer.contains(evt.target)) {
      this.$refs.searchComponent.handleClear();
    }
  }
}
