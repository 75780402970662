











































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// @ts-ignore
// eslint-disable-next-line import/extensions
import anime from 'animejs/lib/anime.es.js';

import ReleaseSearch from '@/shared/components/onboarding/ReleaseSearch.vue';
import SiteLayout from '@/shared/components/site/SiteLayout.vue';
import ModalDspSearch from '@/shared/components/onboarding/ModalDspSearch.vue';

@Component({
  components: {
    ModalDspSearch,
    SiteLayout,
    ReleaseSearch,
  },
})

export default class Future extends Vue {
  $refs!: {
    searchContainer: HTMLElement,
    searchComponent: ReleaseSearch,
    playCount: HTMLElement,
    heartCount: HTMLElement,
    hustleDescription: HTMLElement,
  };

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  @Getter('layout/isTouch') isTouch: boolean;

  searched: boolean = false;

  allowOverflow: boolean = false;

  searchCriteria: string = '';

  lastPosition: number = 0;

  headingAnimation: any;

  zAnimation: any;

  zLeft: any;

  zRight: any;

  hustleAnimation: any;

  slowUpAnimation: any;

  fallingLettersAnimation: any;

  boltAnimation: any;

  investAnimation: any;

  everythingAnimation: any;

  taylorAnimation: any;

  @Watch('isTouch')
  watchIsTouch(newVal: boolean, oldVal: boolean) {
    if (!oldVal && newVal) {
      window.removeEventListener('scroll', this.handleScroll);
      this.resetAnimations();
    } else if (oldVal && !newVal) {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  mounted() {
    this.$store.dispatch('onboarding/clear');
    this.$store.dispatch('mixpanel/resetTrackOnceEventList');
    window.addEventListener('click', this.clearSearch);
    if (!this.isTouch) {
      window.addEventListener('scroll', this.handleScroll);
    }
    this.$store.dispatch('mixpanel/track', {
      action: 'Zire.ManifestoPageViewed',
    });
    this.initAnimation();
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
  }

  destroyed() {
    window.removeEventListener('click', this.clearSearch);
    window.removeEventListener('scroll', this.handleScroll);
  }

  initAnimation() {
    if (!this.headingAnimation) {
      this.headingAnimation = anime({
        targets: '#hero .heading-section',
        translateY: 300,
        autoplay: false,
        easing: 'linear',
      });
    }
    if (!this.zAnimation) {
      this.zAnimation = anime({
        targets: '.zRowAnimation',
        translateY: anime.stagger(-100),
        autoplay: false,
        easing: 'linear',
      });
    }
    if (!this.zLeft) {
      this.zLeft = anime({
        targets: '.zleft',
        translateX: -400,
        autoplay: false,
        easing: 'linear',
      });
    }
    if (!this.zRight) {
      this.zRight = anime({
        targets: '.zright',
        translateX: 400,
        autoplay: false,
        easing: 'linear',
      });
    }
    if (!this.hustleAnimation) {
      this.hustleAnimation = anime({
        targets: '#hustle',
        delay: 120,
        translateY: [-500, 2300],
        translateX: -200,
        autoplay: false,
        easing: 'linear',
      });
    }
    if (!this.slowUpAnimation) {
      this.slowUpAnimation = anime({
        targets: '#floaty-word',
        translateY: 1700,
        autoplay: false,
        easing: 'linear',
      });
    }
    if (!this.fallingLettersAnimation) {
      this.fallingLettersAnimation = anime({
        targets: '.floaty-letter',
        translateY: [anime.stagger(600), anime.stagger(-2000)],
        autoplay: false,
        easing: 'linear',
      });
    }
    if (!this.boltAnimation) {
      this.boltAnimation = anime({
        targets: '#bolt img',
        translateX: [anime.stagger(200), anime.stagger(-600)],
        translateY: [anime.stagger(400), anime.stagger(-800)],
        delay: 200,
        autoplay: false,
        easing: 'linear',
      });
    }
    if (!this.investAnimation) {
      this.investAnimation = anime({
        targets: '#invest-rect',
        delay: 440,
        translateY: -10000,
        duration: 500,
        easing: 'steps(300)',
        autoplay: false,
      });
    }
    if (!this.everythingAnimation) {
      this.everythingAnimation = anime({
        targets: '.movey',
        delay: 570,
        translateY: anime.stagger(-1500),
        duration: 400,
        easing: 'linear',
        autoplay: false,
      });
    }
    if (!this.taylorAnimation) {
      this.taylorAnimation = anime({
        targets: '.taylor',
        delay: 5200,
        translateY: [500, 100],
        easing: 'linear',
        autoplay: false,
      });
    }
  }

  openModalForMobile(evt: any) {
    if (this.isMobile) {
      this.$root.$emit('bv::show::modal', 'modal-dsp-search', evt.target);
      window.scrollTo(0, 0);
    }
  }

  itemClicked(clickedItem?: string) {
    this.$store.dispatch('mixpanel/track', {
      properties: {
        clickedItem,
      },
      action: 'Zire.ManifestoPageItemClicked',
    });
  }

  scrollPercent() {
    const bodyST = document.body.scrollTop;
    const docST = document.documentElement.scrollTop;
    const docSH = document.documentElement.scrollHeight;
    const docCH = document.documentElement.clientHeight;
    const position = ((docST + bodyST) / (docSH - docCH)) * 100;
    this.lastPosition = position;
    // This is to deal with overflow won't play nice with position: sticky
    if (position >= 33 && !this.allowOverflow) {
      this.allowOverflow = true;
    } else if (position < 33 && this.allowOverflow) {
      this.allowOverflow = false;
    }
    return position / 100;
  }

  handleScroll() {
    const scrollPercent = this.scrollPercent();
    this.headingAnimation.seek(scrollPercent * this.headingAnimation.duration);
    this.zAnimation.seek(scrollPercent * this.zAnimation.duration);
    this.zLeft.seek(scrollPercent * this.zLeft.duration);
    this.zRight.seek(scrollPercent * this.zRight.duration);
    this.hustleAnimation.seek(scrollPercent * this.hustleAnimation.duration);
    this.slowUpAnimation.seek(scrollPercent * this.slowUpAnimation.duration);
    this.fallingLettersAnimation.seek(scrollPercent * this.fallingLettersAnimation.duration);
    this.boltAnimation.seek(scrollPercent * this.boltAnimation.duration);
    this.investAnimation.seek(scrollPercent * this.investAnimation.duration);
    this.everythingAnimation.seek(scrollPercent * this.everythingAnimation.duration);
    this.taylorAnimation.seek(scrollPercent * this.taylorAnimation.duration);
    const playCountElem = this.$refs.playCount;
    if (playCountElem && this.elementInViewport(playCountElem) && playCountElem.classList && !playCountElem.classList.contains('play-animation')) {
      playCountElem.classList.add('play-animation');
      this.$refs.heartCount.classList.add('heart-animation');
    }
  }

  resetAnimations() {
    this.headingAnimation.seek(0);
    this.zAnimation.seek(0);
    this.zLeft.seek(0);
    this.zRight.seek(0);
    this.hustleAnimation.seek(0);
    this.slowUpAnimation.seek(0);
    this.fallingLettersAnimation.seek(0);
    this.boltAnimation.seek(0);
    this.investAnimation.seek(0);
    this.everythingAnimation.seek(0);
    this.taylorAnimation.seek(0);
  }

  clearSearch(evt: any) {
    if ((this.$refs.searchContainer && this.$refs.searchComponent.searched) && !this.$refs.searchContainer.contains(evt.target)) {
      this.$refs.searchComponent.handleClear();
    }
  }

  elementInViewport(el: any) {
    let top = el.offsetTop;
    let left = el.offsetLeft;
    const width = el.offsetWidth;
    const height = el.offsetHeight;
    while (el.offsetParent) {
      // eslint-disable-next-line no-param-reassign
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }
    return (
      top < (window.pageYOffset + window.innerHeight)
      && left < (window.pageXOffset + window.innerWidth)
      && (top + height) > window.pageYOffset
      && (left + width) > window.pageXOffset
    );
  }
}
